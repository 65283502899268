#root {
    &.theme-dark {
        color: $DarkWhite !important;
        .orders-frame .inside-box .inside li {
            color: $DarkWhite !important;
            .name-title .strong {
                color: $DarkWhite !important;
            }
        }
        .content {
            background-color: $Gray !important;
        }
        &.admin {
            .box,
            .container .header nav ul,
            .modal .modal-content,
            .orders-frame .inside-box,
            .grid-area-section .section,
            .sidebar, .sidebar .logo-link,
            .container .footer,
            .container .header {
                background-color: $Black !important;
            }
        }
    }
}