$Yellow: #ec9e28;
$LightGray: #d4d4d4;
$AshGray: #aeaeae;
$Black: #000;
$Gray: #3c353c;
$DarkGray: #777;
$Dark: #2d3e50;
$OffWhite: #f9f9f9;
$Cream: #f0f3f6;
$BackGray: #f4f4f4;
$White: #fff;
$Cyan: #2cb5af;
$GreenSea: #16a085;
$Turcoise: #1abc9c;
$Mischka: #CCD3DC;
$DarkWhite: #e4e3e3;
$LightPink: #fff2e2;
$Orange: #f15b29;

$WhatsappColor: #25d366;
$FbColor: #4867aa;
$Progress: #a55eea;
$GoogleColor: #d34d41;
$LinkedinColor: #0a66c2;

$OverlayColor: #0000008c;
$BorderColor: #CAD1DC;
$ShadowColor: #cccccc;
$DisabledColor: #aaa;

$Success: #007600;
$Error: #ea4335;
$Info: #00a6ff;
$Warning: #ffbd46;

$Primary: rgb(244, 100, 100);
$PrimaryLight: rgba(244, 100, 100, 0.8);

$ImageBase: "https://cdn.programmerindia.org/";

$SidebarColor: $White;
$HeaderColor: $White;
$FooterColor: $OffWhite;
$TextColor: $Gray;
$NavTextColor: $Gray;

$generic_font: 'Gilroy', sans-serif;
$heading_font: 'Amita', cursive;

$gameBoxSize: 56.75vh