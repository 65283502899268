@import '../../../assets/scss/vars';
.profile-page {
    background: $OffWhite;
    &.my-orders-profile-section {
        .right-content-section {
            @media (max-width: 991px) {
                overflow-x: scroll;
                width: 100%
            }
        }
    }
    .profile-main-section {
        padding: 80px 0 0 0;
        min-height: 100vh;
        @media (max-width: 991px) {
            padding: 80px 0 0 0;
            min-height: calc(100vh - 80px)
        }
        .left-menu-section {
            max-width: 200px;
            width: 100%;
            @media (max-width: 768px) {
                max-width: 60px
            }
            .nav-list {
                height: 100%;
                .menus {
                    height: 100%;
                    box-shadow: 0 1px 4px $ShadowColor;
                    background: $White;
                    ul {
                        position: sticky;
                        top: 80px;
                        li {
                            border-bottom: 1px solid $DarkWhite;
                            border-right: 1px solid $DarkWhite;
                            border-left: 1px solid $DarkWhite;
                            height: 60px;
                            a, i {
                                width: 100%;
                                cursor: pointer;
                                padding: 10px 20px;
                                height: 100%;
                                font-style: normal;
                                ion-icon {
                                    margin-right: 10px;
                                    color: $Black;
                                    height: 100%;
                                    vertical-align: middle !important
                                }
                                span {
                                    color: $DarkGray
                                }
                            }
                            &:hover {
                                ion-icon,
                                span {
                                    color: $Primary
                                }
                            }
                            &:first-child {
                                border-top: 1px solid $DarkWhite
                            }
                            &:last-child {
                                border-bottom: 1px solid $DarkWhite
                            }
                        }
                    }
                }
            }
        }
        .right-content-section {
            padding: 20px;
            max-width: calc(100% - 200px);
            width: 100%;
            @media (max-width: 768px) {
                max-width: calc(100% - 60px)
            }
            .profile-sec {
                .profile-name-section {
                    width: 100%;
                    max-width: 300px;
                    margin-right: 20px;
                    @media (max-width: 768px) {
                        width: 100%;
                        max-width: 100%
                    }
                    .form-box {
                        max-width: 100%;
                        border-radius: 8px;
                        box-shadow: 0 1px 4px $ShadowColor;
                        padding-bottom: 20px !important;
                        .profile-avatar {
                            text-align: center;
                            padding: 30px;
                            position: relative;
                            .profile-image {
                                max-width: 150px;
                                margin: auto;
                                max-height: 150px;
                                position: relative;
                                cursor: pointer;
                                transition: 0.3s all ease-in-out;
                                &:hover {
                                    .profile-image-hover-shadow {
                                        position: absolute;
                                        background: #d5d5d6c7;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        border-radius: 50%;
                                        transition: 0.3s all ease-in-out;
                                        display: block;
                                        padding: 30px
                                    }
                                }
                                img {
                                    border-radius: 50%;
                                    border: 1px solid $BorderColor;
                                    width: 100%;
                                    height: 100%;
                                    margin: auto;
                                    width: 150px;
                                    height: 150px
                                }
                                .profile-image-hover-shadow
                                {
                                    display: none
                                }
                            }
                            .profile-upload-option {
                                background-color: $White;
                                position: absolute;
                                right: -50px;
                                top: 50px;
                                ul {
                                    padding: 0;
                                    li {
                                        padding: 10px;
                                        border: 1px solid $BackGray;
                                        columns: $BorderColor
                                    }
                                }
                            }
                        }
                        .name-sec {
                            text-align: center;
                            h4 {
                                margin-bottom: 10px
                            }
                        }
                        .description {
                            padding: 15px;
                            p {
                                font-size: 12px
                            }
                            textarea {
                                background-color: $Cream;
                                border: 0;
                                border-radius: 20px;
                                line-height: 18px;
                                height: 50px;
                                display: block;
                                width: 100%;
                                font-size: 14px;
                                color: #1a2d38;
                                padding: 15px;
                                min-height: 200px
                            }
                        }
                    }
                }
                .form-editable-section {
                    width: 100%;
                    .form-box {
                        max-width: 100% !important;
                        border-radius: 8px;
                        box-shadow: 0 1px 4px $ShadowColor;
                        padding: 0;
                        .account-detail {
                            padding: 20px;
                            &.flex {
                                .carSelectDetails {
                                    width: 70%
                                }
                                .col-3{
                                    width: 30%
                                }
                                .pricing-table-grid {
                                    display: block;
                                    margin: 50px 0
                                }
                                .selectPackageTYpe {
                                    .col-9{
                                        width: 100%
                                    }
                                }
                            }
                            .form-group {
                                display: flex;
                                margin-bottom: 15px;
                                .control-label {
                                    padding-top: 4px;
                                    margin-bottom: 0;
                                    flex: 1
                                }
                                .controls {
                                    width: 75%;
                                    padding: 0 15px;
                                    ion-icon {
                                        font-size: 24px !important;
                                        vertical-align: middle
                                    }
                                    .uploadBox {
                                        border: 1px solid $Black;
                                        height: 40px;
                                        padding: 4px;
                                        width: 100%;
                                        text-align: center;
                                        border-radius: 8px;
                                        user-select: none;
                                        position: relative
                                    }
                                    .uploadViewBox {
                                        background: $Primary;
                                        border-radius: 10px;
                                        text-align: center;
                                        height: 40px;
                                        line-height: 40px;
                                        user-select: none;
                                        color: $White;
                                        position: relative
                                    }
                                }
                                input,
                                select {
                                    width: 100%;
                                    border: 1px solid $BorderColor;
                                    padding: 6px 6px 7px
                                }
                                textarea {
                                    resize: none;
                                    min-height: 60px;
                                    width: 100%
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 991px) {
        .profile-main-section {
            .left-menu-section, .right-content-section {
                .profile-sec {
                    .profile-name-section, .form-editable-section {
                        padding: 0;
                        margin: 20px 0;
                        .form-box {
                            max-width: 100%
                        }
                    }
                }
            }
          
        }
    }
    @media only screen and (max-width: 768px) {
        .profile-main-section {
            .left-menu-section {
                .nav-list .menus{
                    max-width: 60px;
                    width: 100%
                }
            }
        }
    }
}