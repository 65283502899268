.grid-area-section {
    grid-gap: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    &.col-1-2 {
        @media (min-width: 1200px) {
            grid-template-columns: 1fr 2fr
        }
    }
    .section {
        position: relative;
        padding: 20px;
        background-color: $White;
        border-radius: 10px;
        height: max-content;
        .delete {
            position: absolute;
            right: 0;
            top: 0;
            background: transparent;
            border: 0;
            line-height: 24px;
            width: 24px;
            color: $Error;
            border-radius: 10px
        }
        h4.head {
            margin: -20px -20px 20px;
            border-bottom: 1px solid $LightGray;
            padding: 0 40px 0 20px;
            position: relative;
            font-size: 16px;
            line-height: 44px;
            font-weight: 300;
            cursor: pointer;
            ion-icon, i {
                position: absolute;
                right: 0;
                width: 24px;
                height: 44px;
                font-size: 10px;
                color: $DarkGray;
                margin: 0 9px
            }
        }
        .body {
            overflow: hidden;
            margin: -20px;
            padding: 20px;
            transition: all 0.3s ease-in-out;
            display: flex;
            .icon-section {
                flex: 1;
                text-align: left;
                align-self: center;
                ion-icon, i {
                    font-size: 32px !important;
                    color: $Dark
                }
                .pending-orders {
                    color: $Warning
                }
                .order-complete {
                    color: $Success
                }
                .wallet {
                    color: $Info
                }
            }
            .description {
                flex: 3;
                .title {
                    font-size: 14px
                }
                .description-stats {
                    font-size: 22px
                }
            }
        }
        &.closed {
            .body {
                max-height: 0;
                padding: 0 20px
            }
        }
    }
}