@import '../../assets/scss/vars';
.main-section {
    section {
        &.lets-talk {
            position: relative;
            background-image: url("#{$ImageBase}connect.jpeg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;
            padding-bottom: 140px;
            color: $White;
            &::before {
                background-color: $OverlayColor;
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0
            }
            .container {
                z-index: 1;
                position: relative;
                max-width: 720px !important;
                padding: 40px !important
            }
        }
        &.what-we-do {
            background-color: $Cream;
            .container {
                max-width: 800px !important;
                padding: 40px !important
            }
        }
        &.white-box {
            position: relative;
            z-index: 1;
            .container {
                margin: -140px auto !important;
                background-color: $White;
                padding: 0 20px !important;
                .right-text {
                    font-family: $heading_font;
                    font-size: 20px;
                    padding-top: 32px;
                    line-height: 44px;
                    color: $Gray;
                    letter-spacing: 2px
                }
                &.flex {@media screen and (min-width: 992px) {
                        .right-text {
                            font-size: 26px;
                            padding-left: 20px;
                            text-align: right
                        }
                    }
                    @media screen and (max-width: 991px) {
                        flex-direction: column;
                        img {
                            max-width: 300px;
                            max-height: 300px;
                            margin: auto
                        }
                    }
                }
            }
        }
        &.our-services .container {
            .service {
                display: flex;
                width: 100%;
                position: relative;
                flex-direction: column;
                background-color: $Dark;
                p {
                    position: absolute;
                    top: calc(50% - 20px);
                    color: $White;
                    background-color: $Dark;
                    left: 0;
                    right: 0;
                    margin: 0;
                    z-index: 1;
                    line-height: 40px
                }
                &.full {
                    img {
                        margin-top: 50%
                    }
                }
                @media (max-width: 1080px) {
                    display: inline-block;
                    width: auto;
                }
            }
            @media (max-width: 560px) {
                flex-direction: column;
                img {
                    margin: 15px 0 !important;
                    width: 100% !important
                }
                .service {
                    display: block !important;
                    background-color: transparent !important
                }
            }
        }
        .container {
            max-width: 1080px !important;
            max-height: unset !important;
            text-align: center;
            padding: 40px 20px !important
        }
        &.banner {
            &.home {
                position: relative;
                min-height: 54vw;
                display: flex;
                background-size: cover;
                background-position: center;
                padding: 60px 20px;
                flex-direction: column;
                justify-content: center;
                background-image: url("#{$ImageBase}banner.jpg");
                align-items: center;
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background-color: $OverlayColor
                }
                .data {
                    position: relative;
                    color: $White;
                    text-align: center;
                    h1 {
                        font-weight: 400;
                        font-size: 40px;
                        span {
                            font-weight: bold
                        }
                    }
                    p.big {
                        .cursive {
                            font-family: $heading_font;
                            font-size: 18px;
                            background-color: $White;
                            color: $Gray;
                            padding: 0px 20px;
                            border-radius: 20px;
                            display: inline-block;
                            margin: 20px 0 40px 2px
                        }
                    }
                }
            }
        }
    }
}