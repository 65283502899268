.blog-page {
    .wrapper {
        margin: 0
    }
    .blog-single-item {
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 30px;
        background-color: $Cream;
        padding: 25px 30px 30px 30px;
        .thumb-area {
            position: relative;
            overflow: hidden;
            .thumb {
                position: relative;
                transition: all 1s;
                &.eventthumb {
                    img {
                        max-height: 300px;
                        object-fit: contain
                    }
                }
                img {
                    width: 100%;
                    height: auto
                }
                &::after {
                    position: absolute;
                    z-index: 1;
                    content: '';
                    display: block;
                    top: 50%;
                    left: 50%;
                    width: 0;
                    height: 0;
                    background-color: rgba(251, 9, 55, 0.1);
                    transition: all 0.5s
                }
            }
            .date {
                position: absolute;
                top: 0;
                right: 0;
                color: $White;
                background-color: $Dark;
                width: 80px;
                height: 90px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                .day {
                    display: block;
                    color: $White;
                    font-size: 30px;
                    line-height: 40px;
                    font-weight: 600
                }
                .month {
                    display: block;
                    color: $White;
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 400
                }
            }
        }
        .content {
            height: 100%;
            display: grid;
            padding: 25px 0 0;
            .title {
                color: $Dark;
                font-size: 30px;
                line-height: 45px;
                margin: 0 0 8px 0;
                font-weight: 600
            }
            .post-meta {
                display: flex;
                -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
                border-bottom: 1px solid rgba(24, 24, 24, 0.3);
                .list-wrap {
                    width: 50%;
                    display: flex;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    .author {
                        position: relative;
                        padding-left: 20px;
                        &::before {
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            content: '';
                            display: inline-block;
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background-color: $Primary
                        }
                    }
                }
                .list-wrap.two {
                    -ms-flex-pack: distribute;
                    justify-content: space-around;
                    color: $Dark;
                    text-align: center;
                    li {
                        padding-left: 5px
                    }
                }
            }
            .event-meta {
                .list-wrap {
                    width: 100%;
                    @media (max-width: 768px) {
                        flex-direction: column
                    }
                    li {
                        margin-right: 20px;
                        ion-icon {
                            vertical-align: middle
                        }
                        .author {
                            padding-left: 0;
                            &::before {
                                display: none
                            }
                        }
                    }
                }
            }
            p {
                color: $Dark;
                font-size: 14px;
                line-height: 24px;
                font-weight: 400;
                margin-bottom: 0
            }
            .read-more-area {
                display: flex;
                .read-more {
                    width: 60%;
                    -ms-flex-item-align: center;
                    align-self: center;
                    a {
                        color: $Primary;
                        font-size: 14px;
                        line-height: 24px;
                        border: 1px solid $Primary;
                        border-radius: 34px;
                        padding: 10px 20px;
                        position: relative;
                        transition: all 0.3s ease-in;
                        &:hover {
                            background-color: $Primary;
                            color: $White
                        }
                        &::before {
                            content: '';
                            display: block;
                            width: 50px;
                            height: 1px;
                            background-color: $Dark;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            visibility: hidden;
                            opacity: 0;
                            transition: all 0.5s
                        }
                    }
                }
                .read-more-event {
                    a {
                        width: 60%;
                        text-align: center
                    }
                }
            }
        }
    }
    .blog-pagination {
        display: block;
        width: 100%;
        text-align: center !important;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            justify-content: center;
            align-items: center;
            li {
                margin: 0 5px;
                display: inline-block;
                &:first-child,
                &:last-child {
                    a {
                        border: none;
                        position: relative;
                        font-size: 25px
                    }
                }
                .current {
                    background-color: $Dark;
                    color: $White
                }
                span,
                .page-numbers {
                    display: block;
                    width: 40px;
                    height: 40px;
                    border: 1px solid #91939D;
                    line-height: 40px;
                    text-align: center;
                    font-weight: 600;
                    transition: .3s ease-in;
                    color: #91939D;
                    font-size: 14px
                }
            }
        }
    }
    .widget-area {
        .widget {
            background-color: $Cream;
            margin-bottom: 30px;
            padding: 25px 30px 30px 30px;
            .search-form {
                position: relative;
                .form-group {
                    margin-bottom: 0 !important
                }
                .form-control {
                    width: 100%;
                    height: 50px;
                    padding: 0 70px 0 15px;
                    display: block;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #495057;
                    background-color: $White !important;
                    background-clip: padding-box;
                    border: 1px solid #ced4da !important;
                    border-radius: .25rem !important;
                    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
                }
                .submit-btn {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 60px;
                    height: 40px;
                    line-height: 40px;
                    padding: 0;
                    font-size: 18px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    background-color: $Primary;
                    color: $White;
                    transition: .3s ease-in;
                    border: none;
                    cursor: pointer
                }
            }
            .widget-title {
                font-size: 22px;
                font-weight: 700;
                line-height: 31px;
                margin-bottom: 20px;
                position: relative;
                color: $Dark;
                &::after {
                    content: '';
                    display: block;
                    border-bottom: 1px solid $Dark;
                    width: 60%;
                    margin-top: 10px
                }
            }
            .recent-post-item {
                margin: 0;
                padding: 0;
                list-style: none;
                max-height: 500px;
                overflow-y: scroll;
                .single-recent-post-item {
                    display: flex;
                    -ms-flex-item-align: start;
                    align-self: flex-start;
                    &:first-child {
                        margin-top: 0px
                    }
                    &:not(:last-child) {
                        border-bottom: 1px solid $BorderColor;
                        padding-bottom: 20px
                    }
                    .thumb {
                        width: 130px;
                        height: 80px;
                        align-items: center;
                        margin-right: 15px;
                        display: flex
                    }
                    .content {
                        display: grid;
                        align-self: center;
                        flex: 1;
                        height: 100%;
                        display: grid;
                        .title {
                            color: $Dark;
                            font-size: 18px;
                            line-height: 27px;
                            font-weight: 500;
                            margin: 0 0 8px 0
                        }
                        .time {
                            font-size: 14px;
                            font-weight: 500;
                            color: $Dark
                        }
                    }
                }
            }
        }
        .widget_nav_menu {
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                    margin: 15px 0;
                    &:first-child {
                        margin-top: 0
                    }
                    a {
                        border: none;
                        position: relative;
                        padding-left: 20px;
                        font-size: 14px;
                        line-height: 24px;
                        color: $Dark;
                        font-weight: 400;
                        &::before {
                            width: 8px;
                            height: 8px;
                            background-color: $Dark;
                            transform: translateY(-50%);
                            left: 0;
                            content: '';
                            display: inline-block;
                            border-radius: 50%;
                            position: absolute;
                            top: 50%;
                            transition: all 0.3s ease-in
                        }
                        &::after {
                            width: 12px;
                            height: 12px;
                            border: 1px solid $Dark;
                            top: 50%;
                            left: 4px;
                            transform: translate(-50%, -50%);
                            content: '';
                            display: inline-block;
                            border-radius: 50%;
                            position: absolute;
                            top: 50%;
                            transition: all 0.3s ease-in
                        }
                    }
                }
            }
        }
    }
    .share-icon li:hover {
        color: $Primary
    }
    ion-icon {
        margin-right: 10px
    }
    li{
        list-style: unset
    }
}