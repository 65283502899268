.pricing-table-grid-box {
    background: $White;
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08), 0px 20px 31px 3px rgba(0, 0, 0, 0.09), 0px 8px 20px 7px rgba(0, 0, 0, 0.02)
}
.subscription .pricing-table-grid-box {
    justify-content: center
}
.pricing-table-grid {
    @media (max-width: 500px) {
        padding: 0 !important
    }
}
.pricing-table-grid-box {
    justify-content: center;
    margin: auto;
    display: flex; 
    @media (max-width: 768px) {
        max-width: 100%
    }
    .pricing-table-grid {
        display: flex;
        justify-content: space-around;
        @media (max-width: 768px) {
            padding-left: 0;
            flex-direction: column
        }
    }
    .pricing-detail-box {
        .grid-Box {
            .middle-part {
                .features {
                    display: flex;
                    justify-content: space-between;
                    text-align: left;
                    i {
                        align-self: center;
                        color: $Dark
                    }
                }
            }
        }
        @media (max-width: 768px) {
            display: none
        }
    }
    .grid-Box {
        height: max-content;
        min-width: 270px;
        background-color: $White;
        &:first-child(), &:last-child() {
            @media (max-width: 768px) {
                margin: 0 !important
            }
        }
        .upper-part {
            height: 56px;
            padding: 20px 10px;
            line-height: 30px;
            text-align: center;
            .plan-heading {
                white-space: nowrap;
                color: $TextColor;
                font-weight: 600;
                letter-spacing: 1px;
                position: relative
            }
            p {
                font-size: 12px;
                display: none
            }
            &:hover {
                p {
                    display: block
                }
            }
        }
        .middle-part {
            padding: 30px 0;
            .features {
                border-bottom: 1px solid $BorderColor;
                height: 40px;
                text-align: center;
                line-height: 40px;
                padding: 0 30px;
                position: relative;
                @media (max-width: 1024px) {
                    line-height: 60px
                }
                @media (max-width: 768px) {
                    padding: 0 10px;
                    line-height: 40px
                }
                &::before {
                    position: absolute;
                    content: '';
                    left: 20px;
                    font-weight: 600;
                    opacity: 0.6
                }
                .feature-details {
                    max-width: 130px;
                    margin: auto;
                    text-align: left;
                    @media (max-width: 768px) {
                        max-width: 44%;
                        text-align: left;
                        margin-left: auto !important;
                        margin: 0
                    }
                    @media (max-width: 500px) {
                        max-width: 43%;
                        font-size: 12px
                    }
                }
                i {
                    color: $Primary;
                    vertical-align: middle;
                    margin-right: 10px;
                    font-size: 16px !important
                }
                @media (max-width: 768px) {
                    &.box1 {
                        &::before {
                            content: 'Preventive Checks'
                        }
                    }
                    &.box2 {
                        &::before {
                            content: 'Car Wash'
                        }
                    }
                    &.box3 {
                        &::before {
                            content: 'Car Cleaning (waterless)';
                            @media (max-width: 400px) {
                                content: 'Car Cleaning'
                            }
                        }
                    }
                    &.box6 {
                        &::before {
                            content: 'Vacuuming'
                        }
                    }
                    &.box7 {
                        &::before {
                            content: 'Air Pressure'
                        }
                    }
                    &.box9 {
                        &::before {
                            content: 'Interior Dusting'
                        }
                    } 
                    &.box11 {
                        &::before {
                            content: 'Services'
                        }
                    }
                    &.box12 {
                        &::before {
                            content: 'Accessories'
                        }
                    }
                    &.box13 {
                        &::before {
                            content: 'Parts / Lubes'
                        }
                    }
                    &.box14 {
                        &::before {
                            content: 'Labour '
                        }
                    }
                }
            }
        }
        .bottom-part .btn{
            margin: auto
        }
    }
    .grid-box-center {
        position: relative;
        .plan-heading {
            p.plan-subheading {
                padding-top: 30px
            }
        }
        .most-popular {
            position: absolute;
            background-color: $Primary;
            position: absolute;
            color: $White;
            padding: 0 10px;
            left: 90px;
            top: 60px;
            font-size: 10px;
            @media (max-width: 768px) {
                left: 40%
            }
            &::after {    position: absolute;
                content: '';
                top: -19px;
                border: 10px solid transparent;
                border-bottom: 10px solid $Primary;
                left: 5px
            }
        }
    }
}
.pricing-table-background {
    margin: 20px 0;
    padding: 20px 0;
    background-color: $Cream;
    border-radius: 30px;
    position: relative
}
.pricing-header-background {
    background-color: $Cream;
    clip-path: polygon(0 1%, 100% 0, 69% 81%, 0% 100%);
    position: absolute;
    height: calc(100vh - 200px);
    top: 0;
    left: 0;
    right: 0;
    width: 1440px;
    margin: 150px auto;
    border-radius: 42px;
    z-index: 0
}
.heading-section-pricing {
    padding-top: 40px;
    text-align: center;
    .heading-table-price {
        line-height: 60px;
        font-size: 50px;
        font-weight: 500;
        &::after {
            display: block;
            content: '';
            background: $Primary;
            width: 60px;
            height: 2px;
            margin-top: 13px;
            margin: auto
        }
    }
    p {
        opacity: 0.75
    }
}
.heading-table-price-p {
    &::after {
        display: block;
        content: '';
        background: $Primary;
        width: 60px;
        height: 2px;
        margin-top: 13px;
        margin: auto
    }
}
.header-inner {
    padding: 50px;
    margin: 150px 0;
    display: flex;
    position: relative;
    z-index: 9;
    .content-left {
        flex: 1;
        align-self: center;
        .box-header {
            line-height: 60px;
            font-size: 50px;
            font-weight: 500;
            &::after {
                display: block;
                content: '';
                background: $Primary;
                width: 40px;
                height: 2px;
                margin-top: 13px
            }
        }
    }
    .image-right {
        align-self: center;
        flex: 1
    }
}
.arrow-box {
    position: absolute;
    bottom: 80px;
    margin: auto;
    left: 50%;
    background: $White;
    z-index: 999;
    width: 60px;
    height: 80px;
    border-radius: 30px;
    box-shadow: 0px -1px 19px 3px rgba(0, 0, 0, 0.33);
    &::before {
        position: absolute;
        content: '';
        border: 2px solid #a9a2a2;
        border-radius: 38px;
        width: 72px;
        height: 66px;
        top: -8px;
        border-bottom: 0 !important;
        left: -8px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0
    }
    ion-icon.md.hydrated {
        color: $Primary;
        font-size: 22px !important;
        justify-content: center;
        margin: auto;
        display: flex;
        height: 100%
    }
}
.selectPackageTYpe {
    .pricing-table-grid-box {
        display: block;
        .pricing-table-grid {
            .middle-part {
                padding: 30px 0;
                .features {
                    .feature-details {
                        margin-right: 0
                    }
                    &.box1{
                        &::before {
                            content: 'Preventive Checks'
                        }
                    }
                    &.box2{
                        &::before {
                            content: 'Car Wash'
                        }
                    }
                    &.box3{
                        &::before {
                            content: 'Car Cleaning (waterless)'
                        }
                    }
                    &.box6{
                        &::before {
                            content: 'Vacuuming'
                        }
                    }
                    &.box7{
                        &::before {
                            content: 'Air Pressure'
                        }
                    }
                    &.box9{
                        &::before {
                            content: 'Interior Dusting'
                        }
                    } 
                    &.box11{
                        &::before {
                            content: 'Services'
                        }
                    }
                    &.box12{
                        &::before {
                            content: 'Accessories'
                        }
                    }
                    &.box13{
                        &::before {
                            content: 'Parts / Lubes'
                        }
                    }
                    &.box14{
                        &::before {
                            content: 'Labour '
                        }
                    }
                }
            }
            .bottom-part .btn{
                margin: auto
            }
        }
    }
}